import React from 'react';
import axios from 'axios';
import { formatPrice } from './NumberFormatting';
import netlifyIdentity from 'netlify-identity-widget';
// import DatePicker from 'react-date-picker';

export const ThToggle = ({ onClick, val, title }) => (<th onClick={onClick}>{title}<br />{(val) ? '☑' : '☐'}</th>);
export const ThToggleAscDesc = ({ onClick, val, title, children }) => (<th onClick={onClick}>{title}<br />{(val === 1) ? '▲' : '▼' }<br />{children}</th>);
export const TdToggle = ({ onClick, val }) => (<td onClick={onClick}>{(val) ? '☑' : '☐'}</td>);
export const BoolYesNo = ({ val }) => val === true ? 'Yes' : 'No';
export const FormatDate = ({ timestamp }) => {
    const ts_ms = parseInt(timestamp, 10);
    const now = (new Date()).getTime();
    if (ts_ms < now && ts_ms > 1605780008736) { // Thu 19th Nov 2020
        const dt = new Date(ts_ms);
        const dateStr = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
        const timeStr = `${dt.getHours()}:${dt.getMinutes()}`;
        return dateStr + ' ' + timeStr;
    } else {
        return '';
    }
};
const ItemsSpan = ({ items }) => {
    const rtv = [];
    let off = 0;
    items.map((item, index) => {
        if (index > 0) rtv.push((<br key={off++}/>));
        rtv.push((<span key={off++}>{item.sku}-{item.variant} * {item.quantity}</span>))
    });
    return rtv;
};

export class PostroomList extends React.Component {
    constructor(props) {
        super(props);
        if (typeof document !== 'undefined') netlifyIdentity.init();
        this.state = {
            filter: {
                paid: true,
                sent: false,
                name: '',
                address: '',
                postcode: ''
            },
            sort: {
                date: -1
            },
            rows: [],
            batch: {
                all: false,
                items: []
            },
            user: null,
            batchCreated: false
        };
        this.axiosInstance = axios.create();
        this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';
        this.axiosInstance.defaults.headers['Cache-Control'] = 'no-cache';
        this.axiosInstance.defaults.withCredentials = true;
        this.queryTimer = null;
        const onLogout = () => {
            this.setState((prevState => {
                return { ...prevState, user: null };
            }));
        };
        const onLogin = (user) => {
            netlifyIdentity.close();
            this.setState((prevState => {
                return { ...prevState, user };
            }));
            if (user !== null) {
                this.axiosInstance.defaults.headers['Authorization'] = `Bearer ${user.token.access_token}`;
                setTimeout(() => {
                    this.dispatchQueryOrders();
                }, 150);
            }
        };
        netlifyIdentity.on('login', onLogin.bind(this));
        netlifyIdentity.on('logout', onLogout.bind(this));

    }

    componentDidMount() {
        const user = netlifyIdentity.currentUser();
        if (user !== this.state.user) {
            this.setState((prev) => {
                return {...prev, user};
            });
            if (user !== null) {
                this.axiosInstance.defaults.headers['Authorization'] = `Bearer ${user.token.access_token}`;
                this.queryOrders();
            }
        }
        console.log({ user });
    }

    queryOrders() {
        const query = {
            filter: this.state.filter,
            sort: this.state.sort
        }
        this.axiosInstance.post(`/.netlify/functions/postroom-get-orders`, JSON.stringify(query)).then((resp) => {
            this.setState((prevState) => {
                return { ...prevState, rows: resp.data };
            });
            if (this.state.batch.all === true) {
                this.resetBatchOnChange({ all: true });
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    sortFunc(prev, next) {
        if (this.state.sort.timestamp === 1) return (next.timestamp < prev.timestamp);
        else return (next.timestamp > prev.timestamp);
    }

    dispatchQueryOrders() {
        if (this.queryTimer === null) {
            this.queryTimer = setTimeout(() => {
                this.queryOrders();
                clearTimeout(this.queryTimer);
                this.queryTimer = null;
            }, 750);
        }
    }

    togglePaid() {
        let filter = this.state.filter;
        filter.paid = !(filter.paid);
        this.setState((prevState => {
            return { ...prevState, filter };
        }));
        this.dispatchQueryOrders();
    }

    toggleSent() {
        let filter = this.state.filter;
        filter.sent = !(filter.sent);
        this.setState((prevState => {
            return { ...prevState, filter };
        }));
        this.dispatchQueryOrders();
    }

    toggleSortField(field) {
        let sort = this.state.sort;
        if (!(field in sort)) sort[field] = 1;
        else sort[field] = (sort[field] === 1) ? -1 : 1;
        this.setState((prevState => {
            return { ...prevState, sort };
        }));
        // this.dispatchQueryOrders();
    }

    resetBatchOnChange({ all }) {
        let batch = this.state.batch;
        let newAll = (typeof all === 'undefined') ? batch.all : all;
        if (batch.all !== newAll) batch.all = newAll;
        batch.items = [];
        if (batch.all === true) {
            let i = 0;
            while(i < this.state.rows.length) {
                batch.items[i] = this.state.rows[i]._id;
                i++;
            }
        }
        this.setState((prevState => {
            return { ...prevState, batch };
        }));
    }

    toggleAllBatch() {
        this.resetBatchOnChange({ all: !(this.state.batch.all) });
    }

    toggleItemBatch(index) {
        let batch = this.state.batch;
        const id = this.state.rows[index]._id;
        const existingIndex = batch.items.findIndex((item) => item === id);
        if (existingIndex > -1) batch.items.splice(existingIndex, 1);
        else batch.items.push(id);
        if (batch.items.length === this.state.rows.length) batch.all = true;
        else batch.all = false;
        this.setState((prevState => {
            return { ...prevState, batch };
        }));
    }

    login(event) {
        event.preventDefault();
        netlifyIdentity.open();
    }

    logout(event) {
        event.preventDefault();
        netlifyIdentity.logout();
    }

    clickCreateBatch(event) {
        event.preventDefault();
        console.log('clickCreateBatch');
        console.dir(this.state.batch.items);
        const payload = {
            items: this.state.batch.items
        };
        this.axiosInstance.post(`/.netlify/functions/postroom-create-batch`, JSON.stringify(payload)).then((resp) => {
            console.log('got data: ');
            console.dir(resp.data);
            if (resp.data.successCount === payload.items.length) {
                this.setState((prev) => {
                    return {...prev, batchCreated: true};
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    render() {
        const openRM = () => {
            const url = 'https://business.parcel.royalmail.com/batch-history/';
            window.open(url, '_blank');
        };
        return (
            <div style={ { marginBottom: '2em', width: '90%' } }>
            <div>
                { (this.state.user === null) ? (<button className='button' onClick={this.login}>Login</button>) : <button className='button' onClick={this.logout}>Logout</button> }
                <button className='button' onClick={this.clickCreateBatch.bind(this)} disabled={this.state.batch.items.length === 0}>Create Royal Mail Batch ({this.state.batch.items.length})</button><nbsp />
                { (this.state.batchCreated === true) ? (<button className="button" onClick={openRM}>Print Postage</button>) : ''}
            </div>
            <table className="table is-fullwidth" style={ { width: '100%', tableLayout: 'fixed' }}>
                <colgroup>
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '4%' }} />
                    <col style={{ width: '4%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                    <col />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '5%' }} />
                </colgroup>
                <thead><tr>
                    <th>ID</th>
                    <ThToggle onClick={this.togglePaid.bind(this)} val={this.state.filter.paid} title='Paid' />
                    <ThToggle onClick={this.toggleSent.bind(this)} val={this.state.filter.sent} title='Sent' />
                    <ThToggleAscDesc onClick={this.toggleSortField.bind(this, 'timestamp')} val={this.state.sort.timestamp} title='Date'></ThToggleAscDesc>
                    <th>Name</th>
                    <th>Details</th>
                    <th>Items</th>
                    <ThToggle onClick={this.toggleAllBatch.bind(this)} val={this.state.batch.all} title='Pick' />
                    <th>Total</th>
                </tr></thead>
                
                <tbody>
                    
                    {this.state.rows.sort(this.sortFunc.bind(this)).map((order, index) => (
                        // return (
                        <tr key={order._id} className='is-size-6'>
                        <td className='is-size-7' style={ { textOverflow: 'ellipsis', overflow: 'hidden' } }>{order._id.substring(0,4)}{order._id.substring(order._id.length-6)}</td>
                        <td><BoolYesNo val={order.payment_paid} /></td>
                        <td><BoolYesNo val={order.shipping_order_created} /></td>
                        <td className='is-size-7'><FormatDate timestamp={order.timestamp} /></td>
                        <td className='is-size-7'>{order.customer.name}</td>
                        <td className='is-size-7'>
                            {order.customer.address_1}{(order.customer.address_2.length > 0) ? ', ' + order.customer.address_2 : ''}, {order.customer.address_city}, {order.customer.address_state}
                            <br/>
                            {order.customer.address_zip} {order.customer.address_country}
                            <br/>
                            <a href={'mailto:' + order.customer.email}>{order.customer.email}</a>
                            <br/>
                            <a href={'tel:' + order.customer.phone}>{order.customer.phone}</a>
                        </td>
                        <td className='is-size-6'><ItemsSpan items={order.items} /></td>
                        <TdToggle onClick={this.toggleItemBatch.bind(this, index)} val={this.state.batch.items.findIndex((item) => item === order._id) > -1} />
                        <td>{formatPrice(order.payment_totalCost)}</td>
                        </tr>

                        // );
                    ))}
                    
                </tbody>
                
            </table>
            </div>
        )
    }
};